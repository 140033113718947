<template>
  <header :class="{ hideWhenPopupOpen: isfaqPopupOpen }">
      <div class="ham_btn" @click="toggleSiteMap">
          <div class="btn btn01"></div>
          <div class="btn btn02"></div>
          <div class="btn btn03"></div>
      </div>

      <a class="center" href="https://afterdoc.co.kr/">
          <img src="@/assets/img/header/logo.png" alt="애프터닥 로고 이미지 | 메디팔" />
      </a>
      <div class="btn_group">
          <div class="site">
              <a class="header_company_btn" href="https://medipal.co.kr/" target="_blank">회사소개</a>
              <span style="color: var(--color_6c);">|</span>
              <button class="header_faq_btn" @click="openBlog">블로그</button>
              <span style="color: var(--color_6c);">|</span>
              <button class="header_faq_btn" @click="openfaqPopup">FAQ</button>
          </div>
          <button class="join_btn" @click="action_Contact">도입 문의</button>
      </div>
  </header>

  <div class="side_btn" :style="isStyled">
      <button class="btn faq_btn" @click="openfaqPopup">
          <img src="@/assets/img/side_btn/faq_btn.png" alt="자주 하는 질문 보기 버튼" />
      </button>
      <button class="btn top_btn" @click="scrollToTop">
          <img src="@/assets/img/side_btn/top_btn.png" alt="페이지 위로 가는 버튼" />
      </button>
  </div>

  <div class="site_map_overlay" :class="{ active: siteMapActive }" @click="closeSiteMap"></div>

  <aside class="site_map" :class="{ active: siteMapActive }">
      <!-- @click.self="closeSiteMap" -->

      <a class="logo" href="https://afterdoc.co.kr/">
          <img src="@/assets/img/site_map/logo.png" alt="애프터닥 로고 이미지 | 메디팔" />
      </a>
      <button type="button" class="join_btn" @click="action_Contact">도입 문의</button>
      <ul class="menu_li">
          <li>
              <a
                  href="https://medipal.co.kr/"
                  target="_
      blank"
                  >회사소개</a
              >
          </li>
          <li @click="openBlog" on-canplay="faq_btn" style="cursor:pointer">
            블로그
          </li>
          <li @click="openfaqPopupAndCloseSiteMap" on-canplay="faq_btn" style="cursor:pointer">FAQ</li>
      </ul>
  </aside>

  <main>
      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
          <div class="main_title">
              <div class="title_k">병원 경영의 핵심</div>
              <div class="title_b">
                  재진율을 높이는<br class="br_460" />
                  가장 앞선 방법
              </div>
          </div>
          <div class="sub_title">
              애프터닥은 모든 내원환자가 병·의원에 <br class="br_460" />
              만족하도록 <br class="br_sub" />
              보다 나은 <br class="br_460" />
              환자관리 프로세스를 만들어갑니다.
          </div>
      </div>
      <button ref="animatedDiv" data-aos="fade-up" data-aos-duration="1400" class="join_btn" @click="action_Contact">도입 문의</button>

      <!-- desktop -->
      <div class="main_slider_container_desktop">
          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="slider_container_desktop_inner">
              <carousel class="second_carousel_slider" :items-to-show="3" :items-to-scroll="1" :wrap-around="true">
                  <slide v-for="slide in imageItems" :key="slide">
                      <img :width="360" :height="360" :src="slide.url" alt="프로세스 예시 이미지" />
                  </slide>
                  <template #addons>
                      <navigation />
                  </template>
              </carousel>
          </div>
      </div>

      <!-- mobile -->
      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1400" class="main_slider_container_mobile">
          <!-- <div class="demo_img">
      <img src="@/assets/img/main/process01.png" alt="프로세스예시이미지">
    </div> -->
          <div class="slider_container_mobile_inner">
              <carousel class="second_carousel_slider" :items-to-show="1" :items-to-scroll="1" :wrap-around="true">
                  <slide v-for="slide in imageItems" :key="slide">
                      <img :width="330" :src="slide.url" alt="프로세스 예시 이미지" />
                  </slide>
                  <template #addons>
                      <navigation />
                  </template>
              </carousel>
          </div>
      </div>
  </main>

  <section class="section section01">
      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
          <div class="main_title">"원장님, 알고 계셨나요?"</div>
          <div class="sub_title">
              많은 병원이 방문환자 중 40%의 환자들로만 <br class="br_422" />운영되고 있습니다.
              <br class="br_512" />
              애프터닥은 <br class="br_422" />
              <span> 놓치고 계셨던 60%의 이탈환자에 집중하여 <br class="br_422" />재방문을 이끌어냅니다.</span>
          </div>
      </div>

      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
          <img src="@/assets/img/section01/Group_2.png" alt="이탈환자 60%중 10%만 유입되어도 기존대비 유입환자 15%상승효과 이미지." />
      </div>
  </section>

  <section class="seciton section02">
      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
          <div class="main_title">
              <span class="title_k">전체 내원환자를 대상으로 진행되는 <br class="br_643" /></span>
              <span class="title_b">환자관리 기반 영업 솔루션, 애프터닥</span>
          </div>
          <div class="sub_title">
              각 병·의원의 업무프로세스에 최적화된 환자관리를 통해, 환자만족도를 높이고
              <br class="br_566" />지속적이고 확정적인 재방문을 이끌어내는 <br class="br_679" />인바운드 영업을 수행합니다.
          </div>
      </div>

      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
          <div class="item item1">
              <div class="box box1">
                  <img src="@/assets/img/section02/solution01.png" alt="환자 개인 맞춤형으로 치료 후 건강관리" />
              </div>
              <div class="box box2">
                  <img src="@/assets/img/section02/solution02.png" alt="지속적 소통을 통한 내원 일정관리" />
              </div>
          </div>
          <div class="arrow">
              <img src="@/assets/img/section02/arrow.svg" alt="" />
          </div>
          <div class="item item2">
              <div class="box box3">
                  <img
                      src="@/assets/img/section02/solution03.png"
                      alt="병·의원 경여지표 개선을 위한 인바운드 영업 수행
        첫번째 Open F/U 환자관리를 통해 추가 매출 창출
        두번째 환자니즈 기반 제품권유로 평균 객단가 증대
        "
                  />
              </div>
          </div>
      </div>
  </section>

  <section class="section section03">
      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
          <div class="main_title">
              <div class="title_b">
                  직접 하기엔 부담스러운 <br class="br_482" />영업팀 확보,<br class="br_688" />
                  <span class="title_k">
                      애프터닥으로 <br class="br_482" />
                      바로 시작해보세요</span
                  >
              </div>
          </div>
          <div class="sub_title">
              상담 전문인력과 병원 전용 플랫폼 시스템을 함께 제공해
              <br class="br_460" />원장님이 원하시던 영업 프로세스가
              <br class="br_479" />
              바로 실행되도록 합니다.
          </div>
      </div>

      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
          <div class="item left">
              <div class="inner_box"></div>
              <div class="box box1">
                  <div class="inner_title_group">
                      <div class="emoji">
                          <img src="@/assets/img/section03/nurse_icon.svg" alt="전담 상담매니저 아이콘" />
                      </div>
                      <div class="inner_title">전담 상담매니저 제공</div>
                  </div>

                  <div class="line"></div>

                  <div class="content">
                      <img src="@/assets/img/section03/chat.png" alt="전담 상담매니저 환자 응대 채팅" />
                  </div>
              </div>
          </div>
          <div class="plus">
              <div class="line line1"></div>
              <div class="line line2"></div>
          </div>
          <div class="item right">
              <div class="inner_box"></div>
              <div class="box box2">
                  <div class="inner_title_group">
                      <div class="emoji">
                          <img src="@/assets/img/section03/platform_icon.svg" alt="병원 전용 플랫폼 아이콘" />
                      </div>
                      <div class="inner_title">병원 전용 플랫폼 제공</div>
                  </div>

                  <div class="line"></div>

                  <div class="content">
                      <img src="@/assets/img/section03/platform_set.png" alt="병원 전용 플랫폼 이미지" />
                  </div>
              </div>
          </div>
      </div>
  </section>

  <div class="blue_box">
      <section class="section section04">
          <div class="bg"></div>

          <div class="devide_line">
              <div class="line line1">
                  <div class="circle"></div>
              </div>
              <div class="text">전담 상담매니저 제공</div>
              <div class="line line2">
                  <div class="circle"></div>
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
              <div class="main_title_2">
                  <span class="title_b">체계적인 환자관리 프로세스를 운영하여, <br class="br_525" /></span>
                  <span class="title_k">
                      성공적인 Open f/u<br class="br_524" />
                      관리체계를 만들어냅니다</span
                  >
              </div>
              <div class="sub_title">
                  상담 전문인력이 환자의 치료 히스토리, 예약 및 방문 현황,<br class="br_536" />
                  상담 내용을<br class="br_556" />
                  모두 고려하여 환자관리를 진행하고 <br class="br_536" />
                  병원과 <br class="br_384" />실시간으로 소통합니다.
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
              <div class="item left">
                  <div class="box box1">
                      <div class="inner_title_group">
                          <div class="inner_title">치료 사이클에 따른 환자안내</div>
                          <div class="inner_sub_title">
                              각 환자의 치료 히스토리에 따라 환자의 방문주기를<br class="br_423" />
                              놓치지 않고 관리하여 <br class="br_423on" />재내원으로 연결합니다.
                          </div>
                      </div>
                      <div class="line"></div>
                      <div class="content">
                          <img src="@/assets/img/section04/treatment_cycle.png" alt="치료 사이클에 따른 환자안내하는 이미지" />
                      </div>
                  </div>
              </div>
              <div class="item right">
                  <div class="box box2">
                      <div class="inner_title_group">
                          <div class="inner_title">환자관리 일정 알림</div>
                          <div class="inner_sub_title">
                              환자가 많고 다양해져도 알림 시스템을 통해<br class="br_412" />
                              체계적으로 대응이 가능합니다.
                          </div>
                      </div>
                      <div class="line"></div>
                      <div class="content">
                          <img src="@/assets/img/section04/notification.png" alt="알림 시스템을 통한 체계적으로 대응하는 이미지" />
                      </div>
                  </div>
                  <div class="box box3">
                      <div class="inner_title_group">
                          <div class="inner_title">환자 관리내용 공유</div>
                          <div class="inner_sub_title">
                              환자관리 중 특이사항 발생 시 담당원장님에게<br class="br_388" />
                              바로 공유하여 재내원 시 참고하실 수<br class="br_431" />
                              있도록 합니다.
                          </div>
                      </div>
                      <div class="line"></div>
                      <div class="content">
                          <img src="@/assets/img/section04/memo.png" alt="환자 관리중 특이사항 발생 시 바로 공유하는 이미지" />
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="section section05">
          <div class="bg"></div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
              <div class="main_title_2">
                  <span class="title_b">환자에게 언제나 일관된 메시지를 전달하도록,<br class="br_362" /> </span>
                  <span class="title_k"> 병원에 맞춘 <br class="br_566on" />완벽한 서비스 최적화</span>
              </div>
              <div class="sub_title">
                  원장님이 원하시는 방향대로만 <br class="br_372" />
                  환자관리가 진행되도록,<br class="br_492" />
                  병원의 치료 프로그램과 업무 매뉴얼을 <br class="br_433" />사전에 모두 숙지합니다.
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
              <div class="item left">
                  <div class="box box1">
                      <div class="inner_title_group">
                          <div class="inner_title">우리 병원 상담매니저</div>
                          <div class="inner_sub_title">
                              병원과 항상 같은 목소리를 낼 수 있도록,<br />
                              담당 병원의 상담 매뉴얼, <br class="br_442" />치료 프로그램을 100% 숙지
                          </div>
                      </div>
                      <div class="line"></div>
                      <div class="content">
                          <img
                              src="@/assets/img/section05/reception.png"
                              alt="상담 매뉴얼, 치료 프로그램을 숙지한 우리 병원 전담 상담매니저가 응대하는 이미지 "
                          />
                      </div>
                  </div>
              </div>
              <div class="item right">
                  <div class="box box2">
                      <div class="inner_title_group">
                          <div class="inner_title">실시간 환자정보 공유</div>
                          <div class="inner_sub_title">
                              관리업무 중 발생하는 정보들을<br />
                              원내 실무자들과 실시간으로 공유
                          </div>
                      </div>
                      <div class="line"></div>
                      <div class="content">
                          <img
                              src="@/assets/img/section05/info_share.png"
                              alt="관리업무 중 발생하는 정보들을 원내 실무자들에게 실시간 공유하는 이미지"
                          />
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="section section06">
          <div class="bg"></div>
          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
              <div class="main_title_2">
                  <div class="title_b">
                      질의응답만 기계적으로 대응하는 것이 아닌,<br class="br_390" />
                      <span class="title_k"> 일상속에서 지속되는 대화와 <br class="br_712" />상담으로 관계를 유지</span>
                  </div>
              </div>
              <div class="sub_title">
                  환자와 치료 후 건강관리를 주제로 깊은 라포를 형성해,
                  <br class="br_768" />
                  지속적인 관계를 유지합니다.<br class="br_780" />
                  병원의 서비스에 대한 감동을 이끌어내 자연스러운 재내원으로 이어지게 합니다.
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="slider_container_desktop">
              <div class="slider_container_desktop_inner">
                  <carousel class="second_carousel_slider" :items-to-show="3" :items-to-scroll="1" :wrap-around="true" :transition="500">
                      <slide v-for="slide in secondImageItems" :key="slide">
                          <img
                              :width="360"
                              height="360"
                              :src="slide.url"
                              alt="
             환자와 1:1 건강 관리를 통해 깊은 라포를 형성하고
             질의응답뿐이 아닌 환자 개인의 건강관리를 위해 노력하며.
        자연스러운 재내원으로 이어지게 하는 이미지. "
                          />
                      </slide>
                      <template #addons>
                          <navigation />
                      </template>
                  </carousel>
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="slider_container_mobile">
              <div class="slider_container_mobile_inner">
                  <carousel class="second_carousel_slider_mobile" :items-to-show="1" :items-to-scroll="1" :wrap-around="true">
                      <slide v-for="slide in secondImageItems" :key="slide">
                          <img :width="360" :src="slide.url" />
                      </slide>
                      <template #addons>
                          <navigation />
                      </template>
                  </carousel>
              </div>
          </div>
      </section>
  </div>

  <div class="orange_box">
      <section class="section section07">
          <div class="bg"></div>
          <div class="devide_line">
              <div class="line line1">
                  <div class="circle"></div>
              </div>
              <div class="text">병원 전용 플랫폼 제공</div>
              <div class="line line2">
                  <div class="circle"></div>
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
              <div class="main_title_2">
                  <div class="title_o">병원 밖 환자들도 관리하는</div>
                  <div class="title_k">우리 병원 전용 플랫폼</div>
              </div>
              <div class="sub_title">
                  언제 어디서든 우리 병원이 생각날 때 치료 설명과 예약 진행이 가능하도록,<br class="br_556" />
                  원내 전용 플랫폼을 제공하여 <br class="br_578" />환자의 <br class="br_377" />재방문율을 높입니다.
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
              <div class="item item1">
                  <div class="box box1">
                      <div class="inner_title">버튼 하나로<br />손쉬운 예약</div>
                      <div class="content">
                          <img src="@/assets/img/section07/choice.png" alt="환자가 플랫폼에서 버튼 하나로 손쉽게 예약 선택하는 화면" />
                      </div>
                  </div>
                  <div class="box box2">
                      <div class="inner_title_group">
                          <div class="inner_title">환자가 원하는 대로</div>
                          <div class="inner_sub_title">
                              전화나 방문 없이 플랫폼에서 예약이<br />
                              빠르고 간편하게 이루어집니다.
                          </div>
                      </div>
                      <div class="content">
                          <img src="@/assets/img/section07/direct_reserv.png" alt="플랫폼에서 환자가 원하는 일시에 환자가 직접 예약 하는 화면" />
                      </div>
                  </div>
              </div>
              <div class="item item2">
                  <div class="box box3">
                      <div class="inner_title_group">
                          <div class="inner_title">걱정하지 마세요</div>
                          <div class="inner_sub_title">
                              모든 예약은 시트 안으로 실시간 반영되어<br />병원과 애프터닥이 동시에 확인,<br class="br_428" />
                              관리가 가능합니다.
                          </div>
                      </div>
                      <div class="content">
                          <img src="@/assets/img/section07/reservation.png" alt="애프터닥 소프트웨어에서 모든 예약 알림을 확인하고 관리하는 화면" />
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="section section_n">
          <div class="bg"></div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
              <div class="main_title_2">
                  <div class="title_o">
                      단골환자들을 위한 특별한 혜택과 이벤트를<br class="br_537" />
                      <span class="title_k">
                          <br class="br_418" />
                          우리 병원 플랫폼에서 <br class="br_537" />언제나 자유롭게
                      </span>
                  </div>
              </div>
              <div class="sub_title">
                  자체 채널에서 진행되는 소통과 이벤트를 통해
                  <br class="br_445" />병·의원은 <br class="br_539" />비용을 줄이고,<br class="br_564" />
                  환자들은 보다 풍성한 혜택과 이벤트를 <br class="br_539" />제공받을 수 있습니다.
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
              <div class="item left">
                  <div class="box box1">
                      <div class="inner_title_group">
                          <div class="inner_title">우리 병원 공지사항</div>
                          <div class="inner_sub_title">
                              병원 앱을 통해 공지사항과 이벤트를<br />
                              알리고 소통할 수 있습니다.
                          </div>
                      </div>
                      <div class="line"></div>
                      <div class="content">
                          <img src="@/assets/img/section_n/notice.png" alt="우리 병원 공지사항 팝업이 띄워져 있는 이미지 " />
                      </div>
                  </div>
              </div>
              <div class="item right">
                  <div class="box box2">
                      <div class="inner_title_group">
                          <div class="inner_title">내원환자 전용 이벤트</div>
                          <div class="inner_sub_title">
                              오직 내원환자만을 위한 공간에서<br />
                              특별한 이벤트와 상품을 <br class="br_376" />제안할 수 있습니다.
                          </div>
                      </div>
                      <div class="line"></div>
                      <div class="content">
                          <img
                              src="@/assets/img/section_n/event.png"
                              alt="내원환자만을 위한 이벤트와 원내 판매중인 상품을 앱을 통해
              구매 상담까지 이어지는 이미지"
                          />
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="section section08" ref="animation_section08">
          <div class="bg"></div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
              <div class="main_title_2">
                  <div class="title_o">
                      기존 환자를 넘어 소개 환자까지,<br class="br_398" />
                      <span class="title_k"> 홈케어 콘텐츠로 만드는 <br class="br_447" />입소문 효과</span>
                  </div>
              </div>
              <div class="sub_title">
                  환자가 경험하는 홈케어 콘텐츠를 비슷한 증상의 지인에게 공유합니다.<br class="br_556" />
                  우리 병원만의 콘텐츠로 소개환자도 <br class="br_536" />방문하게 만드세요.
              </div>
          </div>

          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
              <div class="item item1">
                  <div class="content">
                      <img src="@/assets/img/section08/chat.png" alt="환자가 경험하는 홈케어 콘텐츠를 비슷한 증상의 지인에게 공유하는 이미지." />
                  </div>
              </div>
              <div class="item item2">
                  <div class="box box1">
                      <div class="arrow_group group1">
                          <div class="arrow"></div>
                          <div class="arrow"></div>
                          <div class="arrow"></div>
                      </div>
                      <div class="content">
                          <img src="@/assets/img/section08/human_link.svg" alt="기존 환자를 통한 소개환자 유도로 점점 퍼져가는 이미지" />
                      </div>
                      <div class="arrow_group group2">
                          <div class="arrow"></div>
                          <div class="arrow"></div>
                          <div class="arrow"></div>
                      </div>
                  </div>
                  <div class="box box2">
                      <div class="link_icon">
                          <img src="@/assets/img/section08/link_icon.svg" alt="링크 아이콘" />
                      </div>
                      <div class="text">
                          공유 받은 링크를 통해 <br />
                          우리 병원 소개/예약 페이지로 바로 이동
                      </div>
                  </div>
              </div>
              <div class="item item3">
                  <div class="content">
                      <img src="@/assets/img/section08/hospital_info.png" alt="공유 받은 링크를 통해 우리 병원 소개/예약 페이지로 이동한 화면" />
                  </div>
                  <div class="btn">
                      예약 문의하기
                      <div class="cursor">
                          <img src="@/assets/img/section08/cursor.svg" alt="마우스커서" />
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </div>

  <div class="gradient_bg"></div>

  <section class="section section09" ref="animation_section09">
      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
          <div class="main_title">
              <div class="title_k">시간이 지날수록 더욱 강력해지는</div>
              <div class="title_b">우리 병원만의 자체 채널</div>
          </div>
          <div class="sub_title">
              우리 병원 내원환자로만 구성되어 <br class="br_457" />언제든지 활용이 가능한,<br />
              병원의<br class="br_390" />
              가장 큰 자체 채널로서 <br class="br_575" />성장하게 됩니다.
          </div>
      </div>

      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
          <div class="item">
              <div class="box">
                  <div class="content">
                      <img
                          class="pc"
                          src="@/assets/img/section09/mockup_icon.png"
                          alt="시간이 지나 더욱 강력해진 우리 병원만의 자체 채널 이미지 "
                      />
                      <img class="tb" src="@/assets/img/section09/mockup_tb.png" alt="시간이 지나 더욱 강력해진 우리 병원만의 자체 채널 이미지 " />
                      <img class="mo" src="@/assets/img/section09/mockup_mo.png" alt="시간이 지나 더욱 강력해진 우리 병원만의 자체 채널 이미지 " />
                  </div>
                  <div class="text_box">
                      <div class="txt">우리 병원 누적 환자</div>
                      <div class="num_wrapper">
                          <span class="number">{{ count }}</span>
                          <span class="ct">명</span>
                      </div>
                  </div>
                  <div class="human_box">
                      <div class="human s_human">
                          <img src="@/assets/img/section09/s_human.svg" alt="작은 사람" />
                      </div>
                      <div class="human m_human">
                          <img src="@/assets/img/section09/m_human.svg" alt="점점 커지는 사람" />
                      </div>
                      <div class="human l_human">
                          <img src="@/assets/img/section09/l_human.svg" alt="엄청 커진 사람" />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="section section10">
      <div class="bg"></div>

      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
          <div class="main_title">환자관리에 <span>진심</span>을 다하는, 애프터닥</div>
          <div class="sub_title">
              원장님, 애프터닥과 함께<br />
              병원의 브랜드 이미지도 더욱 높여보세요.
          </div>
      </div>

      <div class="belt">
          <div class="box box1">
              <div class="row1">
                  잠을 깊게 못 자던 때가 있었어요 숙면에 좋은<br class="br_960" />
                  것들 얼마나 이것저것 해보라고 알려주시던지...<br class="br_960" />
                  맞는 방법이 나올 때까지 포기하지 않는 선생님의<br class="br_960" />
                  모습에 감동했습니다. 다행히 전보다 잠이 좀<br class="br_960" />
                  늘었어요! <br class="br_pc" />다음 진료 때 규칙적으로 잘 자고 <br class="br_960" />있다고 칭찬받을 거 같아요^^
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">초O령</div>
              </div>
          </div>
          <div class="box box2">
              <div class="row1">
                  ... 보통 병원 가면 뭐 먹지 말고 하지 말라는 말만 듣는데, 여기 병원
                  <br class="br_960 br_pc" />에서는 포기하지 않게 '그것<br class="br_960" />보다는 이게 좀 더 낫다', '하루쯤 놓치더라도 또 이렇게
                  해보면 된다' 이렇게 부담 없이 접근해 주시는 게 <br class="br_960 br_pc" />환자들 마음까지 알고 계시구나 하고 감동이었습니다.
                  고맙습니다.
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">아O</div>
              </div>
          </div>
          <div class="box box3">
              <div class="row1">
                  평소 건강 쪽에 관심이 많아서 열심히 운동하고
                  <br class="br_960" />관리한다고 했는데 맞게 가고 있는 건지 확인받고 <br class="br_960" />싶었어요, 상담매니저님 께서 잘하고
                  <br class="br_pc" />있다고 응원도 해주시고 많은 도움을 얻고 있습니다. <br class="br_960" />감사합니다!!
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">강O하</div>
              </div>
          </div>
          <div class="box box4">
              <div class="row1">
                  60대 저희 부모님이 진료받았는데, 친절하고
                  <br class="br_960" />알아듣기 쉽게 설명도 놓치지 않고 해주시고, <br class="br_960" />다른 것보다 전화나 시계 아니면 핸드폰
                  <br class="br_pc" />사용을 <br class="br_960" />안 하시는 부모님도 쉽게 확인할 수 있도록 <br class="br_960" />어플 만드신
                  <br class="br_pc" />점들. 하나하나 신경 써주셨어요. <br class="br_960" />별 다섯 개가 아깝지 않아요~!!
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">이O미</div>
              </div>
          </div>
          <div class="box box5">
              <div class="row1">
                  몸 상태가 안 좋아져서 많이 예민해지고
                  <br class="br_960" />걱정했는데.. 상담매니저분이 계속 챙겨주시고 <br class="br_960" />이것저것 방법도 알려주시고 해서 진짜 진짜
                  <br class="br_pc" />너무 <br class="br_960" />안심됐어요!
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">박O민</div>
              </div>
          </div>
          <div class="box box6">
              <div class="row1">
                  ...아무 생각 없이 알림 와서 읽어보면 상담매니저<br class="br_960" />분과 좋은 얘기들 하면서 그냥 또 그렇게 하루가
                  <br class="br_960" />마무리되고, 점점 습관이 된 거 같아요. 누가 <br class="br_960" />물어봐 주니깐 저도 저를 돌아보게 되고..
                  <br class="br_960" />병원에서 저를 궁금해해주시는 느낌이 이제 <br class="br_960" />적응이 돼서 병원 가기가 더 편해졌어요!
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">김O준</div>
              </div>
          </div>
          <div class="box box7">
              <div class="row1">
                  예약도 바로 말씀드리면 되고 남은 약도 다 <br class="br_960" />떨어져갈 때쯤 다음 진료
                  <br class="br_pc" />
                  잡아주셔서 정말 <br class="br_960" />최고에요~ 좋아요
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">방O라</div>
              </div>
          </div>
          <div class="box box8">
              <div class="row1">
                  한 달에 한 번 약 타러 병원 가는데 진료 때마다
                  <br class="br_960" />증상 어땠는지 물어보면 까먹고 말 못하 던 <br class="br_960" />것들도 많았는데, 여기 통해서 그래도 며칠마다
                  <br class="br_960" />제 상태를 말씀드려 놓으니까 원장님이 미리 알고 <br class="br_960" />알려주니 <br class="br_pc" />빨리 낫는 거
                  같아요^^ 계속 여기 <br class="br_960" />다닐 거예요~~
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">허O경</div>
              </div>
          </div>
          <div class="box box1">
              <div class="row1">
                  잠을 깊게 못 자던 때가 있었어요 숙면에 좋은<br class="br_960" />
                  것들 얼마나 이것저것 해보라고 알려주시던지...<br class="br_960" />
                  맞는 방법이 나올 때까지 포기하지 않는 선생님의<br class="br_960" />
                  모습에 감동했습니다. 다행히 전보다 잠이 좀<br class="br_960" />
                  늘었어요! <br class="br_pc" />다음 진료 때 규칙적으로 잘 자고 <br class="br_960" />있다고 칭찬받을 거 같아요^^
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">초O령</div>
              </div>
          </div>
          <div class="box box2">
              <div class="row1">
                  ... 보통 병원 가면 뭐 먹지 말고 하지 말라는 말만 듣는데, 여기 병원
                  <br class="br_960 br_pc" />에서는 포기하지 않게 '그것<br class="br_960" />보다는 이게 좀 더 낫다', '하루쯤 놓치더라도 또 이렇게
                  해보면 된다' 이렇게 부담 없이 접근해 주시는 게 <br class="br_960 br_pc" />환자들 마음까지 알고 계시구나 하고 감동이었습니다.
                  고맙습니다.
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">아O</div>
              </div>
          </div>
          <div class="box box3">
              <div class="row1">
                  평소 건강 쪽에 관심이 많아서 열심히 운동하고
                  <br class="br_960" />관리한다고 했는데 맞게 가고 있는 건지 확인받고 <br class="br_960" />싶었어요, 상담매니저님 께서 잘하고
                  <br class="br_pc" />있다고 응원도 해주시고 많은 도움을 얻고 있습니다. <br class="br_960" />감사합니다!!
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">강O하</div>
              </div>
          </div>
          <div class="box box4">
              <div class="row1">
                  60대 저희 부모님이 진료받았는데, 친절하고
                  <br class="br_960" />알아듣기 쉽게 설명도 놓치지 않고 해주시고, <br class="br_960" />다른 것보다 전화나 시계 아니면 핸드폰
                  <br class="br_pc" />사용을 <br class="br_960" />안 하시는 부모님도 쉽게 확인할 수 있도록 <br class="br_960" />어플 만드신
                  <br class="br_pc" />점들. 하나하나 신경 써주셨어요. <br class="br_960" />별 다섯 개가 아깝지 않아요~!!
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">이O미</div>
              </div>
          </div>
          <div class="box box5">
              <div class="row1">
                  몸 상태가 안 좋아져서 많이 예민해지고
                  <br class="br_960" />걱정했는데.. 상담매니저분이 계속 챙겨주시고 <br class="br_960" />이것저것 방법도 알려주시고 해서 진짜 진짜
                  <br class="br_pc" />너무 <br class="br_960" />안심됐어요!
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">박O민</div>
              </div>
          </div>
          <div class="box box6">
              <div class="row1">
                  ...아무 생각 없이 알림 와서 읽어보면 상담매니저<br class="br_960" />분과 좋은 얘기들 하면서 그냥 또 그렇게 하루가
                  <br class="br_960" />마무리되고, 점점 습관이 된 거 같아요. 누가 <br class="br_960" />물어봐 주니깐 저도 저를 돌아보게 되고..
                  <br class="br_960" />병원에서 저를 궁금해해주시는 느낌이 이제 <br class="br_960" />적응이 돼서 병원 가기가 더 편해졌어요!
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">김O준</div>
              </div>
          </div>
          <div class="box box7">
              <div class="row1">
                  예약도 바로 말씀드리면 되고 남은 약도 다 <br class="br_960" />떨어져갈 때쯤 다음 진료
                  <br class="br_pc" />
                  잡아주셔서 정말 <br class="br_960" />최고에요~ 좋아요
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">방O라</div>
              </div>
          </div>
          <div class="box box8">
              <div class="row1">
                  한 달에 한 번 약 타러 병원 가는데 진료 때마다
                  <br class="br_960" />증상 어땠는지 물어보면 까먹고 말 못하 던 <br class="br_960" />것들도 많았는데, 여기 통해서 그래도 며칠마다
                  <br class="br_960" />제 상태를 말씀드려 놓으니까 원장님이 미리 알고 <br class="br_960" />알려주니 <br class="br_pc" />빨리 낫는 거
                  같아요^^ 계속 여기 <br class="br_960" />다닐 거예요~~
              </div>
              <div class="row2">
                  <div class="left">
                      <img src="@/assets/img/section10/star.svg" alt="별아이콘5개" />
                  </div>
                  <div class="right">허O경</div>
              </div>
          </div>
      </div>
  </section>

  <section class="section11">
      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
          <div class="main_title">이런 원장님들께<span> 추천</span>드려요!</div>
      </div>

      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="wrapper">
          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="item item1">
              <img
                  class="option_pc"
                  src="@/assets/img/section11/option01.png"
                  alt="신규개원 혹은 개원 3년 이내,
   개원 초 궁금증과 기대감을 가지고 방문하는 환자분들이
   더 만족하여 단골환자가 될 수 있도록 특별한 관리경험을 선사합니다.
   시작부터 꾸준히 쌓여가는 환자자산을 애프터닥으로 관리하여
   신환 유치비용의 효과를 높이고 빠른 경영안정화를 도모할 수 있습니다. "
              />
              <img
                  class="option_mo"
                  src="@/assets/img/section11/option01_m.png"
                  alt="신규개원 혹은 개원 3년 이내,
   개원 초 궁금증과 기대감을 가지고 방문하는 환자분들이
   더 만족하여 단골환자가 될 수 있도록 특별한 관리경험을 선사합니다.
   시작부터 꾸준히 쌓여가는 환자자산을 애프터닥으로 관리하여
   신환 유치비용의 효과를 높이고 빠른 경영안정화를 도모할 수 있습니다. "
              />
          </div>
          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1400" class="item item2">
              <img
                  class="option_pc"
                  src="@/assets/img/section11/option02.png"
                  alt="병원 확장/이전 준비,
        확장/이전 전에 체계적인 시스템을 통한
환자 관리 시스템을 구축해 보세요.
확장/이전간 잠시간의 공백을 메우고, 더 크고 새로운 곳에서
많아질 환자들에 대한 체계적인 관리를 수행할 수 있습니다. "
              />
              <img
                  class="option_mo"
                  src="@/assets/img/section11/option02_m.png"
                  alt="병원 확장/이전 준비,
        확장/이전 전에 체계적인 시스템을 통한
환자 관리 시스템을 구축해 보세요.
확장/이전간 잠시간의 공백을 메우고, 더 크고 새로운 곳에서
많아질 환자들에 대한 체계적인 관리를 수행할 수 있습니다. "
              />
          </div>
          <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1500" class="item item3">
              <img
                  class="option_pc"
                  src="@/assets/img/section11/option03.png"
                  alt=" 내부 인력 교체가 잦은 곳,
  잦은 인력 교체로 환자들과의 관계가 무너지고
  점점 기계적인 응대로만 변해가는 것이 걱정되신다면?
  
  항상 유지되는 상담매니저를 통해 환자들과의 라포가 끊기지 않도록 
  지속적으로 관리해드립니다. "
              />
              <img
                  class="option_mo"
                  src="@/assets/img/section11/option03_m.png"
                  alt=" 내부 인력 교체가 잦은 곳,
  잦은 인력 교체로 환자들과의 관계가 무너지고
  점점 기계적인 응대로만 변해가는 것이 걱정되신다면?
  
  항상 유지되는 상담매니저를 통해 환자들과의 라포가 끊기지 않도록 
  지속적으로 관리해드립니다. "
              />
          </div>
      </div>
  </section>

  <section class="section12">
      <div ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="title_group">
          <div class="main_title">
              <span> 환자가 다시 찾고 싶은 <br class="br_420" />병원 만들기,<br class="br_556" /></span>
              지금 전문가와 <br class="br_360" />상담을 시작해 보세요
          </div>
      </div>
      <button ref="animatedDiv" data-aos="fade-up" data-aos-duration="1300" class="join_btn" @click="action_Contact">도입 문의</button>
  </section>

  <footer class="footer">
      <div class="box box1">
          <div class="logo">
              <img src="@/assets/img/footer/logo_w.svg" alt="메디팔 로고 이미지" />
          </div>
          <div style="font-size: 18px;">(주)메디팔</div>
      </div>
      <div class="box box2">
          <div class="row row1">
              <span>대표: 강종일</span>
              <span>전화: 070-5180-4070</span>
              <span>이메일: contact@medipal.co.kr</span>
          </div>
          <div class="row row2">서울특별시 강남구 테헤란로 420, 16층 (KT 선릉타워 West)</div>
          <div class="row row3">
              <span>사업자 등록번호: 494-88-01653</span>
          </div>
      </div>
      <!-- <div class="link">
    <a href="https://medipal.co.kr/" target="_blank">회사소개</a>
  </div> -->

      <div class="copyright">Copyright 2023. Medipal Inc. All Rights Reserved</div>
  </footer>

  <PopupFAQ v-if="isfaqPopupOpen" @action_Close="action_Close" />
</template>

<script>
import PopupFAQ from "../../Share/Popup/Pop_FAQ.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
components: {
  PopupFAQ,
  Carousel,
  Slide,
  Navigation,
},
created() {
  
},
mounted() {
  AOS.init(); // AOS 초기화
  this.counterFn();
  this.initIntersectionObserver();
  // Intersection Observer 생성 및 등록
  const observer = new IntersectionObserver(this.checkIntersection, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });
  const footer = document.querySelector(".footer");
  observer.observe(footer);
},
updated() {
AOS.refresh(); // Vue 컴포넌트 업데이트 시 AOS 새로고침
},
computed: {
  isStyled: function () {
    let style = {};
    if (this.isfaqPopupOpen) {
      style = {
        display: "none",
      };
    }
    return style;
  },
},
data() {
  return {
    count: 0,
    id0: null,
    siteMapActive: false,
    showButton: false,
    isfaqPopupOpen: false,
    imageItems: [
      {
        url: require("@/assets/img/main/process01.png"),
        alt: "고객채팅 예시 이미지",
      },
      {
        url: require("@/assets/img/main/process02.png"),
        alt: "예약관리 시트 예시 이미지",
      },
      {
        url: require("@/assets/img/main/process03.png"),
        alt: "홈케어 콘텐츠 예시 이미지",
      },
      {
        url: require("@/assets/img/main/process04.png"),
        alt: "예약현황 예시 이미지",
      },
    ],
    secondImageItems: [
      {
        url: require("@/assets/img/section06/chat_01.png"),
        alt: "질의응답뿐이 아닌 환자 개인의 건강관리를 하는 사례 1번",
      },
      {
        url: require("@/assets/img/section06/chat_02.png"),
        alt: "질의응답뿐이 아닌 환자 개인의 건강관리를 하는 사례 2번",
      },
      {
        url: require("@/assets/img/section06/chat_03.png"),
        alt: "질의응답뿐이 아닌 환자 개인의 건강관리를 하는 사례 3번",
      },
    ],
    showSideButton: false,
    
    identifier: '',
  };
},
watch: {
  isfaqPopupOpen: {
    handler: function (newValue) {
      if (newValue == true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    },
  },
  '$route': {
    immediate: true,
    deep: true,
    handler: function(to) {
      if (to.params.key == undefined || to.params.key == '') { return }
      this.identifier = to.params.key
    }
  },
},
methods: {
  counterFn() {
    this.id0 = setInterval(this.count0Fn, 1);
  },
  count0Fn() {
    this.count += 21; // 11씩 증가
    if (this.count > 16979) {
      clearInterval(this.id0);
    } else {
      this.$forceUpdate(); // Vue 업데이트 강제 갱신
    }
  },
  startCounting(entries) {
    const [entry] = entries;
    if (entry.isIntersecting) {
      this.counterFn(); // section09가 화면에 보일 때 카운트 시작
    } else {
      clearInterval(this.id0); // section09가 화면에서 벗어나면 카운트 중단
    }
    if (entry.isIntersecting) {
      entry.target.classList.add("active");
      // AOS.refresh({ container: entry.target });
    } else {
      entry.target.classList.remove("active");
    }
  },

  beforeUnmount() {
    clearInterval(this.id0);
  },
  toggleSiteMap() {
    this.siteMapActive = !this.siteMapActive;
  },
  closeSiteMap() {
    this.siteMapActive = false;
  },
  openfaqPopupAndCloseSiteMap() {
    this.openfaqPopup();
    this.closeSiteMap();
  },
  checkIntersection(entries) {
    const [entry] = entries;
    this.showSideButton = !entry.isIntersecting;
  },
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  openfaqPopup() {
    this.isfaqPopupOpen = true;
  },
  openBlog() {
    window.location.href = "https://blog.afterdoc.co.kr"
  },

  action_Contact() {
    this.$router.push({name: 'ContactPage', params: {key: 'home'}})
  },
  action_Close() {
    this.isfaqPopupOpen = false;
  },
  closefaqPopuup() {
    this.isfaqPopupOpen = false;
  },
  initIntersectionObserver() {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target.classList.contains("section09")) {
            if (entry.isIntersecting) {
              this.count = 0;
              this.counterFn(); // section09가 화면에 보일 때 카운트 시작
            } else {
              clearInterval(this.id0); // section09가 화면에서 벗어나면 카운트 중단
            } // section09의 가시성 변화 감지
          }
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
            AOS.refresh({ container: entry.target });
          } else {
            entry.target.classList.remove("active");
          }
        });
      },
      { threshold: 0.5 } // 기준점에 도달할 때까지의 비율 (0부터 1까지)
    );
    sectionObserver.observe(this.$refs.animation_section09);
    sectionObserver.observe(this.$refs.animation_section08);
    const allSections = document.querySelectorAll(".section");
    allSections.forEach((section) => {
      sectionObserver.observe(section);
    });
  },
},
};
</script>

<style>
/* 첫번째 슬라이더 모바일 버전 */
@media screen and (min-width: 1200px) {
.main_slider_container_mobile {
  display: none !important;
}

}
/* main .carousel__track{
transform: translateX(0px);
} */
.section06 .slider_container_desktop_inner .carousel__track{
margin: 0 -952px !important;
}
.main_slider_container_mobile li img{
border-radius: 10px;
}
.main_slider_container_mobile {
width: 100%;
display: flex;
padding: 0px;
height: auto;
justify-content: center;
align-items: flex-end;
}
.main_slider_container_mobile .carousel__viewport {
height: 100%;
width: 100%;
}
.main_slider_container_mobile .carousel.second_carousel_slider {
padding: 0px;
width: 100% !important;
height: 100% !important;
}

/* .main_slider_container_mobile .demo_img{
position: absolute;
left: 0;
top:0;
width:100%;
}
.main_slider_container_mobile .demo_img img{
width:100%;
} */

.main_slider_container_mobile .carousel__track{
margin: 0 -400% !important;
}

.slider_container_mobile_inner {
max-width: 90vw;
height: 100%;
width: 100%;
}
/* .main_slider_container_mobile .carousel__slide.carousel__slide--clone {
overflow: hidden;
height: 100%;
object-fit: cover;
width: 100%;
} */
.main_slider_container_mobile
.carousel__slide.carousel__slide--visible.carousel__slide--active {
height: 100%;
padding-bottom: 60px;
padding: 20px;
padding-bottom: 50px;
}
.main_slider_container_mobile
.carousel__slide.carousel__slide--visible.carousel__slide--active
img {
height: 100%;
width: 100%;
box-shadow: var(--blue_shadow);
border-radius: 10px;
}
/* .main_slider_container_mobile .carousel__slide.carousel__slide--clone img {
width: 100%;
border-radius: 10px;
object-fit: contain;
box-shadow: var(--blue_shadow);
} */

/* prev, next btn */
.main_slider_container_mobile button {
bottom: 0px;
height: 0%;
}
.main_slider_container_mobile .carousel__prev {
top: initial;
bottom: -20px;
width: 42px;
height: 42px;
margin: 0;
transform: none;
left: calc(43% - 30px);
}
.main_slider_container_mobile .carousel__prev svg {
width: 42px;
height: 42px;
background-image: url("@/assets/img/main/prev_btn.svg");
fill: none;
}
.main_slider_container_mobile .carousel__next {
top: initial;
bottom: -20px;
width: 42px;
height: 42px;
margin: 0;
transform: none;
right: calc(43% - 30px);
}
.main_slider_container_mobile .carousel__next svg {
width: 42px;
height: 42px;
fill: none;
background-image: url("@/assets/img/main/next_btn.svg");
}

/* 메인 슬라이더 500px 이하일 경우 */
@media screen and (max-width: 500px) {
main .slider_container_mobile_inner {
  max-width: 100vw;
  height: 100%;
}

.main_slider_container_mobile {
  height: 100%;
  display: flex;
}
}
/* 모바일 버전 끝 */

/* 첫번째 슬라이더 데스크탑 버전 */
@media screen and (max-width: 1199px) {
.main_slider_container_desktop {
  display: none !important;
}
}

.main_slider_container_desktop {
width: 100%;
display: flex;
justify-content: center;
height: 430px;
}
.slider_container_desktop_inner {
width: 1100px;
}
.slider_container_desktop_inner .carousel__track {
align-items: center;
}
.slider_container_desktop_inner .carousel.second_carousel_slider {
padding: 0px;
height: 100%;
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--prev {
width: 186px !important;
height: 241px;
display: flex;
justify-content: flex-start;
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--prev
img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: left;
border-radius: 16px;
filter: brightness(0.5);
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--active {
width: 580px !important;
height: 370px;
margin: 0 74px;
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--active
img {
width: 100%;
height: 100%;
border-radius: 16px;
box-shadow: var(--blue_shadow);
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--next {
width: 186px !important;
height: 241px;
display: flex;
justify-content: flex-end;
filter: brightness(0.5);
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--next
img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: left;
border-radius: 16px;
}
.main_slider_container_desktop .carousel__prev {
width: 244px;
display: flex;
justify-content: flex-end;
}
.main_slider_container_desktop .carousel__prev svg {
width: 42px;
height: 42px;
background-image: url("@/assets/img/main/prev_btn.svg");
fill: none;
}
.main_slider_container_desktop .carousel__next svg {
width: 42px;
height: 42px;
fill: none;
background-image: url("@/assets/img/main/next_btn.svg");
}
.main_slider_container_desktop .carousel__next {
width: 244px;
display: flex;
justify-content: flex-start;
}
.main_slider_container_desktop .carousel__viewport {
padding: 30px 0;
box-sizing: border-box;
}
.main_slider_container_desktop .carousel__slide {
opacity: 0;
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--prev {
opacity: 1;
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--active {
opacity: 1;
}
.main_slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--next {
opacity: 1;
}
.main_slider_container_desktop .carousel__prev {
margin: 0;
height: 42px;
}
.main_slider_container_desktop .carousel__next {
margin: 0;
height: 42px;
}
@media screen and (min-width: 1921px) {
.main_slider_container_desktop {
  width: 1920px !important;
  margin: 0 auto;
}
.slider_container_desktop_inner {
  width: 100% !important;
}
}
@media screen and (min-width: 1919px) {
.main_slider_container_desktop {
  width: 100vw;
  display: flex;
  justify-content: center;
  height: 680px;
}
.slider_container_desktop_inner {
  width: 100vw;
}
.slider_container_desktop_inner .carousel__track {
  align-items: center;
}
.slider_container_desktop_inner .carousel.second_carousel_slider {
  padding: 0px;
  height: 100%;
}
.main_slider_container_desktop
  .carousel__slide.carousel__slide--visible.carousel__slide--prev {
  width: 340px !important;
  height: 440px;
  margin-left: 66px;
  opacity: 1;
}
.main_slider_container_desktop
  .carousel__slide.carousel__slide--visible.carousel__slide--prev
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  border-radius: 16px;
}
.main_slider_container_desktop
  .carousel__slide.carousel__slide--visible.carousel__slide--active {
  width: 1000px !important;
  height: 640px;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 54px;
  opacity: 1;
}
.main_slider_container_desktop
  .carousel__slide.carousel__slide--visible.carousel__slide--active
  img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: var(--blue_shadow);
}
.main_slider_container_desktop
  .carousel__slide.carousel__slide--visible.carousel__slide--next {
  width: 340px !important;
  height: 440px;
  opacity: 1;
}
.main_slider_container_desktop
  .carousel__slide.carousel__slide--visible.carousel__slide--next
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  border-radius: 16px;
}
.main_slider_container_desktop .carousel__prev {
  width: 464px;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
.main_slider_container_desktop .carousel__prev svg {
  width: 42px;
  height: 42px;
  background-image: url("@/assets/img/main/prev_btn.svg");
  fill: none;
}
.main_slider_container_desktop .carousel__next svg {
  width: 42px;
  height: 42px;
  fill: none;
  background-image: url("@/assets/img/main/next_btn.svg");
}
.main_slider_container_desktop .carousel__next {
  width: 464px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}
}

/* 데스크탑 버전 끝 */

/* 두번째 슬라이더 모바일 버전 */
@media screen and (max-width: 959px) {
.slider_container_desktop {
  display: none !important;
}
}
.slider_container_mobile {
width: 100%;
display: flex;
justify-content: center;
}
.section06 .slider_container_mobile .carousel__slide {
padding: 30px;
box-sizing: border-box;
}
.slider_container_mobile .carousel__slide img {
max-width: 360px;
max-height: 778px;
width: 100%;
height: 100%;
box-shadow: var(--blue_shadow);
border-radius: 16px;
}
.slider_container_mobile
.carousel__slide.carousel__slide--visible.carousel__slide--active
img {
width: 100%;
box-sizing: border-box;
}
.slider_container_mobile_inner section {
padding-bottom: 0;
padding-top: 0 !important;
}

.section06 .slider_container_mobile button {
width: 40px;
height: 40px;
top: 50%;
bottom: inherit;
margin: 0;
display: flex;
justify-content: center;
align-items: center;
}
.section06 .slider_container_mobile button .carousel__icon {
width: 40px;
height: 40px;
}
@media screen and (max-width: 640px) {
.section06 .slider_container_mobile button {
  width: 30px;
  height: 30px;
}
.section06 .slider_container_mobile button .carousel__icon {
  width: 30px;
  height: 30px;
}
}
.slider_container_mobile .carousel__prev {
top: 0px;
}
.slider_container_mobile .carousel__prev svg {
background-image: url("@/assets/img/section06/prev.svg");
fill: none;
background-position: center;
background-size: 100%;
}
.slider_container_mobile button {
height: 100%;
align-items: flex-end;
bottom: 0px;
display: flex;
}
.slider_container_mobile .carousel__next {
top: 0px;
}
.slider_container_mobile .carousel__next svg {
background-image: url("@/assets/img/section06/next.svg");
fill: none;
background-position: center;
background-size: 100%;
}

/* 두번째 슬라이더 모바일 버전 끝 */

/* 두번째 슬라이더 데스크탑 버전  */
@media screen and (min-width: 960px) {
.slider_container_mobile {
  display: none !important;
}
}
.slider_container_desktop {
max-width: 952px;
display: flex;
justify-content: center;
margin: 0 auto;
}
.slider_container_desktop .carousel__slide {
opacity: 0;
transform: 5s;
}
.slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--prev {
justify-content: center;
width: 256px !important;
margin-left: 40px;
opacity: 1;
}
.slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--prev
img {
overflow: hidden;
max-width: 160px;
height: 345px;
width: 100%;
border-radius: 16px;
opacity: 0.6;
box-shadow: var(--blue_shadow);
}
.slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--active {
max-width: 360px;
width: 100% !important;
opacity: 1;
}
.slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--active
img {
opacity: 1;
width: 100%;
height: 778px;
border-radius: 16px;
box-shadow: var(--blue_shadow);
}
.slider_container_desktop .carousel__viewport {
padding: 40px 0px;
box-sizing: border-box;
}
.slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--next {
width: 256px !important;
justify-content: center;
margin-right: 40px;
opacity: 1;
}
.slider_container_desktop
.carousel__slide.carousel__slide--visible.carousel__slide--next
img {
overflow: hidden;
max-width: 160px;
height: 345px;
width: 100%;
border-radius: 16px;
opacity: 0.6;
box-shadow: var(--blue_shadow);
}
.slider_container_desktop .carousel__prev {
transform: translateY(-50%);
top: 50%;
}
.slider_container_desktop .carousel__prev svg {
background-image: url("@/assets/img/section06/prev.svg");
fill: none;
}
.slider_container_desktop button {
bottom: 0px;
transform: none;
margin: 0;
width: 40px;
height: 40px;
}
.slider_container_desktop .carousel__next {
top: 50%;
transform: translateY(-50%);
}
.slider_container_desktop .carousel__next svg {
background-image: url("@/assets/img/section06/next.svg");
fill: none;
}
/* 두번째 슬라이더 끝 */
</style>
