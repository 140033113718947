<template>
    <!-- step2 -->
    <div class="regist_form_step_wrap">
        <div class="regist_scroll_wrap">
            <div class="regist_form_top">
                <div class="title">
                    <div class="status_txt">
                        <div class="ltitle">카카오 상담톡</div>
                        <div class="mtitle">2. 연동정보 입력</div>
                    </div>
                </div>
                <div class="status">
                    <span class="circle"></span>
                    <span class="circle on"></span>
                </div>
            </div>
            <div class="regist_form_box">
                
                <div class="regist_scroll">
                    <div class="form_set">
                        <b class="form_title">채널검색용 아이디<span class="red_txt">*</span></b>
                        <input type="text" placeholder="카카오 채널 관리자센터 > 검색용 아이디(예.@afterdoc_talk)" v-model="pf_id">
                        <small class="err" v-if="isEmptyID">채널검색용 아이디를 입력해 주세요.</small>
                    </div>
                    <div class="form_set">
                        <b class="form_title">상담가능일시<span class="red_txt">*</span></b>
                        <div class="check_wrap_row">
                            <label class="check_wrap">
                                <input type="checkbox" v-model="possibleDays_Mon">
                                <span class="checkmark">월</span>
                            </label>
                            <label class="check_wrap">
                                <input type="checkbox" v-model="possibleDays_Tue">
                                <span class="checkmark">화</span>
                            </label>
                            <label class="check_wrap">
                                <input type="checkbox" v-model="possibleDays_Wed">
                                <span class="checkmark">수</span>
                            </label>
                            <label class="check_wrap">
                                <input type="checkbox" v-model="possibleDays_Thu">
                                <span class="checkmark">목</span>
                            </label>
                            <label class="check_wrap">
                                <input type="checkbox" v-model="possibleDays_Fri">
                                <span class="checkmark">금</span>
                            </label>
                            <label class="check_wrap">
                                <input type="checkbox" v-model="possibleDays_Sat">
                                <span class="checkmark">토</span>
                            </label>
                            <label class="check_wrap">
                                <input type="checkbox" v-model="possibleDays_Sun">
                                <span class="checkmark">일</span>
                            </label>
                        </div>
                        <small class="err" v-if="isEmptyPossibleDays">요일을 1개 이상 선택해 주세요.</small>
                        <div class="check_wrap_row">
                            <select name="startHour" id="startHour" v-model="startHour">
                                <option 
                                    v-for="(hour, index) in Array.from({length: 24}, (_, i) => {return i})"
                                    :key="index"
                                    :value="hour"
                                >
                                    {{String(hour).padStart(2, '0')}}시
                                </option>
                            </select>
                            <select name="startMin" id="startMin" v-model="startMin">
                                <option 
                                    v-for="(min, index) in Array.from({length: 60}, (_, i) => {return i})"
                                    :key="index"
                                    :value="min"
                                >
                                    {{String(min).padStart(2, '0')}}분
                                </option>
                            </select>
                            <span>~</span>
                            <select name="endHour" id="endHour" v-model="endHour">
                                <option 
                                    v-for="(hour, index) in Array.from({length: 24}, (_, i) => {return i})"
                                    :key="index"
                                    :value="hour"
                                >
                                    {{String(hour).padStart(2, '0')}}시
                                </option>
                            </select>
                            <select name="endMin" id="endMin" v-model="endMin">
                                <option 
                                    v-for="(min, index) in Array.from({length: 60}, (_, i) => {return i})"
                                    :key="index"
                                    :value="min"
                                >
                                    {{String(min).padStart(2, '0')}}분
                                </option>
                            </select>
                        </div>
                        <small class="err" v-if="isWrongTimeSets">종료시각이 시작시각보다 빠릅니다.</small>
                    </div>
                </div>
            </div>    
        </div>
        <div class="regist_button_wrap">
            <button class="btn_regist_back" @click="action_Back">
                <img src="@/assets/img/regist_thirdparty/ic_regist_arrow_left.png" alt="">
                이전으로
            </button>
            <button class="btn_regist_blue" @click="action_Next">신청완료</button>
        </div>
    </div>
</template>


<script>
const dayjs = require('dayjs')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
const tz = 'Asia/Seoul'
dayjs.tz.setDefault(tz)

export default {
    props: {
        model: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            isChecked: false,

            pf_id: undefined,
            possibleDays_Mon: false,
            possibleDays_Tue: false,
            possibleDays_Wed: false,
            possibleDays_Thu: false,
            possibleDays_Fri: false,
            possibleDays_Sat: false,
            possibleDays_Sun: false,
            possibleStartedAt: undefined,
            possibleEndedAt: undefined,

            startHour: 0,
            startMin: 0,
            endHour: 0,
            endMin: 0
        }
    },
    watch: {
        model: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.pf_id = newValue.pf_id
                this.possibleDays_Mon = newValue.possibleDays_Mon
                this.possibleDays_Tue = newValue.possibleDays_Tue
                this.possibleDays_Wed = newValue.possibleDays_Wed
                this.possibleDays_Thu = newValue.possibleDays_Thu
                this.possibleDays_Fri = newValue.possibleDays_Fri
                this.possibleDays_Sat = newValue.possibleDays_Sat
                this.possibleDays_Sun = newValue.possibleDays_Sun
                this.possibleStartedAt = newValue.possibleStartedAt ?? dayjs(0).startOf('days')
                this.possibleEndedAt = newValue.possibleEndedAt ?? dayjs(0).startOf('days')

                if (this.possibleStartedAt != undefined) {
                    let s = dayjs(this.possibleStartedAt)
                    this.startHour = s.format('H')
                    this.startMin = s.format('m')
                }
                if (this.possibleEndedAt != undefined) {
                    let e = dayjs(this.possibleEndedAt)
                    this.endHour = e.format('H')
                    this.endMin = e.format('m')
                }
            }
        },
        startHour: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.possibleStartedAt = dayjs(0).startOf('days').add(newValue, 'hour').add(this.startMin, 'minute').format()
            }
        },
        startMin: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.possibleStartedAt = dayjs(0).startOf('days').add(this.startHour, 'hour').add(newValue, 'minute').format()
            }
        },
        endHour: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.possibleEndedAt = dayjs(0).startOf('days').add(newValue, 'hour').add(this.endMin, 'minute').format()
            }
        },
        endMin: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.possibleEndedAt = dayjs(0).startOf('days').add(this.endHour, 'hour').add(newValue, 'minute').format()
            }
        },
        pf_id: { handler: function() { this.update() } },
        possibleDays_Mon: { handler: function() { this.update() } },
        possibleDays_Tue: { handler: function() { this.update() } },
        possibleDays_Wed: { handler: function() { this.update() } },
        possibleDays_Thu: { handler: function() { this.update() } },
        possibleDays_Fri: { handler: function() { this.update() } },
        possibleDays_Sat: { handler: function() { this.update() } },
        possibleDays_Sun: { handler: function() { this.update() } },
        possibleStartedAt: { handler: function() { this.update() } },
        possibleEndedAt: { handler: function() { this.update() } },
    },
    computed: {
        isEmptyID: function() {
            if (this.isChecked == false) { return false }
            if (this.pf_id == undefined || this.pf_id == '') { return true }
            return false
        },
        isEmptyPossibleDays: function() {
            if (this.isChecked == false) { return false }
            if (this.possibleDays_Mon ||
                this.possibleDays_Tue ||
                this.possibleDays_Wed ||
                this.possibleDays_Thu ||
                this.possibleDays_Fri ||
                this.possibleDays_Sat ||
                this.possibleDays_Sun
            ) { return false }
            return true
        },
        isWrongTimeSets: function() {
            let s = dayjs(this.possibleStartedAt)
            let e = dayjs(this.possibleEndedAt)
            if (e.isBefore(s)) { return true }
            return false
        },
        canNext: function() {
            if (this.isChecked == false) { return false }
            if (this.isEmptyID || this.isEmptyPossibleDays || this.isWrongTimeSets) { return false }
            return true
        }
    },
    methods: {
        action_Back() {
            this.$emit('back')
        },
        action_Next() {
            this.isChecked = true
            if (!this.canNext) { return }
            this.$emit('next')
        },
        // Emit
        update() {
            this.$emit('update', {
                pf_id: this.pf_id,
                possibleDays_Mon: this.possibleDays_Mon,
                possibleDays_Tue: this.possibleDays_Tue,
                possibleDays_Wed: this.possibleDays_Wed,
                possibleDays_Thu: this.possibleDays_Thu,
                possibleDays_Fri: this.possibleDays_Fri,
                possibleDays_Sat: this.possibleDays_Sat,
                possibleDays_Sun: this.possibleDays_Sun,
                possibleStartedAt: this.possibleStartedAt,
                possibleEndedAt: this.possibleEndedAt,
            })
        }
    }
}
</script>