<template>
    <!-- 박지호 수정필요 : 채널연동과 사전정보 신청 갈림길 -->
    <div class="regist_thirdparty_select">
        <div class="title_wrap">
            <div class="ltitle">무엇을 도와드릴까요?</div>
            <div class="description">
                아래 메뉴를 클릭하면 해당 메뉴로 이동합니다.</div>
        </div>
        <div class="thirdparty_list main">
            <button @click="action_Preinform">
                <div class="info">
                    <div class="channel_name">사전 정보 등록하기</div>
                    <div class="channer_desc">서비스 시작 전 최적화된 솔루션 세팅을 위해 <br>필요한 정보를 미리 입력할 수 있습니다.</div>
                </div>
                <div class="info_bg">
                    <img src="@/assets/img/regist_thirdparty/regist.png" alt="">
                </div>
            </button>
            <button @click="action_KakaoFriend">
                <div class="info">
                    <div class="channel_name">채널 연동 신청하기</div>
                    <div class="channer_desc">카카오 상담톡/알림톡/친구톡 등 다양한 채널을<br>애프터닥에 연결할 수 있습니다.</div>
                </div>
                <div class="info_bg">
                    <img src="@/assets/img/regist_thirdparty/link.png" alt="">
                </div>
            </button>
        </div>
    </div>
</template>


<script>
export default {
    methods: {
        action_Preinform() {
            this.$router.push({name: 'RegistPreinform'})
        },
        action_KakaoFriend() {
            this.$router.push({name: 'RegistChannels'})
        },
    }
}
</script>