<template>
    <div class="regist_form_wrap">
        <LoadingIndicator :isShow="isLoading"/>
        <div class="regist_description">
            <img src="@/assets/img/regist_thirdparty/ic_exclamation_circle.png" alt="">
            <div>
                카카오톡 연동 신청 시 <b class="black_txt">비즈니스 채널 인증이 완료</b>되어 있어야 합니다. 비즈니스 채널 인증 방법은 <a href="https://therapeutic-lavender-21e.notion.site/15070b880bbf80d49553cb76ad6a1b22" target="_blank">매뉴얼</a>을 확인해 주세요.
            </div>
        </div>
        
        <Step1
            v-show="isStep1"
            :model="model"
            @update="update"
            @next="action_Next"
            @back="action_Back"
        />
        <Step2
            v-show="isStep2"
            :model="model"
            @update="update"
            @next="action_Next"
            @back="action_Back"
        />
        <Done
            v-show="isDone"
            :model="model"
            @update="update"
            @next="action_Next"
            @back="action_Back"
        />
    </div>
</template>

<script>
import axios from 'axios'
import LoadingIndicator from '@/views/RegistThirdParty/Loading/LoadingIndicator.vue'
import Step1 from './RegistKakaoConsultStep1.vue'
import Step2 from './RegistKakaoConsultStep2.vue'
import Done from './RegistKakaoConsultDone.vue'



const PhaseEnum = {
    step1: 'step1',
    step2: 'step2',
    done: 'done'
}

export default {
    components: { LoadingIndicator, Step1, Step2, Done },
    data() {
        return {
            isLoading: false,
            phase: PhaseEnum.step1,

            model: {
                hospitalName: undefined,
                realName: undefined,
                phone: undefined,

                pf_id: undefined,
                possibleDays_Mon: false,
                possibleDays_Tue: false,
                possibleDays_Wed: false,
                possibleDays_Thu: false,
                possibleDays_Fri: false,
                possibleDays_Sat: false,
                possibleDays_Sun: false,
                possibleStartedAt: undefined,
                possibleEndedAt: undefined,
            }
        }
    },
    computed: {
        isStep1: function() { return this.phase === PhaseEnum.step1 },
        isStep2: function() { return this.phase === PhaseEnum.step2 },
        isDone: function() { return this.phase === PhaseEnum.done },
    },
    methods: {
        // MARK: - Network
        async createModel() {
            this.isLoading = true
            this.model.tempHospitalName = this.model.hospitalName
            this.isLoading = true
            let result = await axios({
                method: 'post',
                url: process.env.VUE_APP_KAKAO_REGIST + '/regist/kakao/consult',
                data: this.model,
                headers: {
                    'content-type': 'application/json'
                }
            })
            this.isLoading = false
            if (result.data.code != 0) {
                alert(result.data.message)
                this.isLoading = false
                return
            }
            this.phase = PhaseEnum.done
            this.isLoading = false
        },

        // MARK: - Emit
        action_Back() {
            if (this.isStep1) {
                this.$router.push({name: 'RegistChannels'})
            }
            else if (this.isStep2) {
                this.phase = PhaseEnum.step1
            }
        },
        action_Next() {
            if (this.isStep1) {
                this.phase = PhaseEnum.step2
            }
            else if (this.isStep2) {
                this.createModel()
            }
        },

        update(newModel) {
            this.model = {
                ...this.model,
                ...newModel
            }
        }
    }
}
</script>