<template>
    <!-- step2 -->
    <div class="regist_form_step_wrap">
        <LoadingIndicator :isShow="isLoading"/>
        <div class="regist_scroll_wrap">
            <div class="regist_form_top">
                <div class="title">
                    <div class="status_txt">
                        <div class="ltitle">카카오 알림톡</div>
                        <div class="mtitle">2. 연동정보 입력</div>
                    </div>
                </div>
                <div class="status">
                    <span class="circle"></span>
                    <span class="circle on"></span>
                </div>
            </div>
            <div class="regist_form_box">
                <div class="regist_scroll">
                    <div class="form_set">
                        <b class="form_title">채널검색용 아이디<span class="red_txt">*</span></b>
                        <input type="text" placeholder="카카오 채널 관리자센터 > 검색용 아이디(예.@afterdoc_talk)" :disabled="!isKakaoPhaseInit" v-model="pf_id">
                        <small class="err" v-if="isEmptyPfId">채널검색용 아이디를 입력해 주세요.</small>
                    </div>
                    <div class="form_set">
                        <b class="form_title">관리자 휴대폰번호<span class="red_txt">*</span></b>
                        <div class="input_set_row">
                            <input type="text" class="grow" placeholder="01012345678" :disabled="!isKakaoPhaseInit" v-model="contact_phone">
                            <button class="btn_input_set" @click="action_GetCode" v-if="isKakaoPhaseInit && !isKakaoPhaseDone">인증요청</button>
                            <button class="btn_input_set line" v-if="isKakaoPhaseCode" @click="action_Reset">재입력</button>
                        </div>
                        <small class="err" v-if="isEmptyContactPhone">관리자 휴대폰번호를 입력해 주세요.</small>
                        <div class="input_set_row" v-if="!isKakaoPhaseInit">
                            <input type="text" class="grow" placeholder="인증번호 입력" v-model="code">
                        </div>
                        <small class="err" v-if="isEmptyCode && isKakaoPhaseCode">인증번호를 입력해 주세요.</small>
                        <small class="err" v-if="!isEmptyContactPhone && needGetCode && isChecked">인증요청을 진행해 주세요.</small>
                    </div>
                    <div class="form_set">
                        <b class="form_title">채널 카테고리<span class="red_txt">*</span></b>
                        <div class="check_wrap_row category_select">
                            <select name="" id="lCategories" v-model="selectedLCategory">
                                <option :value="0" disabled>대카테고리</option>
                                <option v-for="(value, index) in lCategories" :key="index+1" :value="index+1">{{value.broadCategoryName}}</option>
                            </select>
                            <select name="" id="mCategories" v-model="selectedMCategory">
                                <option :value="0" disabled>중카테고리</option>
                                <option v-for="(value, index) in currentMCategories" :key="index+1" :value="index+1">{{value.mediumCategoryName}}</option>
                            </select>
                            <select name="" id="sCategories" v-model="selectedCategoryCode">
                                <option value="00000000000" disabled>소카테고리</option>
                                <option v-for="(value, index) in currentSCategories" :key="index+1" :value="value.code">{{ value.smallCategoryName }}</option>
                            </select>
                        </div>
                        <small class="err" v-if="isEmptyCategories">카테고리를 선택해 주세요.</small>
                    </div>
                </div>
            </div>    
        </div>
        <div class="regist_button_wrap">
            <button class="btn_regist_back" @click="action_Back">
                <img src="@/assets/img/regist_thirdparty/ic_regist_arrow_left.png" alt="">
                이전으로
            </button>
            <button class="btn_regist_blue" :disabled="isEmptyCode" @click="action_Next">신청완료</button>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import LoadingIndicator from '@/views/RegistThirdParty/Loading/LoadingIndicator.vue'

const KakaoPhaseEnum = {
    init: 'init',
    code: 'code',
    done: 'done'
}
export default {
    components: {LoadingIndicator},
    props: {
        model: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            isChecked: false,
            isCheckedCode: false,
            isLoading: false,

            pf_id: undefined,
            contact_phone: undefined,
            senderKey: undefined,
            selectedCategoryCode: '00000000000',
            categoryL: '',
            categoryM: '',
            categoryS: '',

            selectedLCategory: 0,
            selectedMCategory: 0,

            code: undefined,
            lCategories: [],
            mCategories: [],
            sCategories: [],

            kakaoPhase: KakaoPhaseEnum.init,
        }
    },
    watch: {
        model: {
            immediate: true,
            handler: function(newValue) {
                this.pf_id = newValue.pf_id
                this.contact_phone = newValue.contact_phone
                this.senderKey = newValue.senderKey
                this.selectedCategoryCode = newValue.selectedCategoryCode ?? '00000000000'
            }
        },
        selectedLCategory: function(newValue, oldValue) {
            if (newValue == oldValue) { return }
            this.categoryL = ''
            this.selectedMCategory = 0
            this.selectedCategoryCode = '00000000000'
        },
        selectedMCategory: function(newValue, oldValue) {
            if (newValue == oldValue) { return }
            this.selectedCategoryCode = '00000000000'
        }
    },
    computed: {
        isEmptyPfId: function() {
            if (this.isCheckedCode == false) { return false }
            return this.pf_id == undefined || this.pf_id == ''
        },
        isEmptyContactPhone: function() {
            if (this.isCheckedCode == false) { return false }
            return this.contact_phone == undefined || this.contact_phone == ''
        },
        isEmptyCategories: function() {
            if (this.isChecked == false) { return false }
            if (this.isCheckedCode == false) { return false }
            return this.selectedCategoryCode == undefined || this.selectedCategoryCode == '' || this.selectedCategoryCode == '00000000000'
        },
        isEmptyCode: function() {
            if (this.isChecked == false) { return false }
            if (this.isCheckedCode == false) { return false }
            return this.code == undefined || this.code == ''
        },
        needGetCode: function() {
            if (this.kakaoPhase == KakaoPhaseEnum.init) { return true }
            return false
        },
        canNext: function() {
            if (this.isChecked == false) { return false }
            return !this.isEmptyPfId && !this.isEmptyContactPhone && !this.isEmptyCategories && !this.needGetCode
        },

        categoryLTitle: function() {
            if (this.selectedLCategory - 1 < 0) { return undefined }
            return this.lCategories[this.selectedLCategory].broadCategoryName
        },
        categoryMTitle: function() {
            if (this.selectedMCategory - 1 < 0) { return undefined }
            return this.currentMCategories[this.selectedMCategory - 1].mediumCategoryName
        },
        categorySTitle: function() {
            if (this.selectedCategoryCode == '00000000000') { return undefined }
            let category = this.currentSCategories.find( (c) => c.code == this.selectedCategoryCode )
            return category?.smallCategoryName
        },

        currentMCategories: function() {
            if (this.selectedLCategory - 1 < 0) { return [] }
            return this.mCategories[this.selectedLCategory - 1]
        },
        currentSCategories: function() {
            if (this.selectedMCategory - 1 < 0) { return }
            return this.currentMCategories[this.selectedMCategory - 1].smallCategory
        },

        isKakaoPhaseInit: function() { return this.kakaoPhase === KakaoPhaseEnum.init },
        isKakaoPhaseCode: function() { return this.kakaoPhase === KakaoPhaseEnum.code },
        isKakaoPhaseDone: function() { return this.kakaoPhase === KakaoPhaseEnum.done },
    },
    mounted() {
        this.loadCategory()
    },
    methods: {
        // MARK: - Network
        async loadCategory() {
            this.isLoading = true
            let result = await axios({
                method: 'get',
                url: process.env.VUE_APP_KAKAO_REGIST + '/regist/kakao/category',
                headers: {
                    'content-type': 'application/json'
                }
            })
            this.isLoading = false

            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.categories = result.data.data
            this.lCategories = this.categories
            this.mCategories = this.categories.map( (c => {return c.subCategory}))
        },
        async getVerificationCode() {
            this.isLoading = true
            let result = await axios({
                method: 'get',
                url: process.env.VUE_APP_KAKAO_REGIST + '/regist/kakao/code',
                params: {
                    pf_id: this.pf_id,
                    contact_phone: this.contact_phone
                },
                headers: {
                    'content-type': 'application/json'
                }
            })
            this.isLoading = false
            let response = result.data
            if (response?.data?.code != 200) {
                alert(response?.data?.message ?? '서버로 부터 응답을 받지 못했습니다.')
                return
            }

            this.kakaoPhase = KakaoPhaseEnum.code
        },
        async requestCreate() {
            this.isLoading = true
            let result = await axios({
                method: 'post',
                url: process.env.VUE_APP_KAKAO_REGIST + '/regist/kakao/friend',
                data: {
                    ...this.model,
                    pf_id: this.pf_id,
                    contact_phone: this.contact_phone,
                    senderKey: this.senderKey,
                    selectedCategoryCode: this.selectedCategoryCode,
                    category_l: this.categoryLTitle,
                    category_m: this.categoryMTitle,
                    category_s: this.categorySTitle,
                    code: this.code
                },
                headers: {
                    'content-type': 'application/json'
                }
            })
            this.isLoading = false
            
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            if (result.data.data.code != 200) {
                alert(result.data.data.message)
                return
            }
            this.kakaoPhase = KakaoPhaseEnum.done
            this.$emit('next')
        },

        // MARK: - Actions
        action_GetCode() {
            this.isCheckedCode = true
            if (this.isEmptyPfId || this.isEmptyContactPhone) { return }
            this.getVerificationCode()
        },
        action_Back() {
            this.$emit('back')
        },
        action_Next() {
            this.isChecked = true
            this.isCheckedCode = true
            if (!this.canNext) { return }
            this.requestCreate()
        },
        action_Reset() {
            this.kakaoPhase = KakaoPhaseEnum.init
        },
    }
}
</script>