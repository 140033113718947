<template>
    <!-- 신청완료 -->
    <div class="regist_form_step_wrap">
        <div class="regist_done">
            <img src="@/assets/img/regist_thirdparty/img_done.png" alt="">
            <div><b>카카오 상담톡 연동 신청</b>이 완료되었습니다.<br> 담당자가 확인 후 연락드리겠습니다.</div>
            <button class="btn_regist_blue" @click="action_Done">다른 채널 연동</button>
        </div>
    </div>
</template>


<script>
export default {
    methods: {
        action_Done() {
            this.$router.push({name: 'RegistChannels'})
        }
    }
}
</script>