<template>
    <div v-show="isShow" class="dm_pop_overlay">
        <div style="position: absolute; width: 100%; height:100%">
            <button style="width: 100%; height: 100%; background-color: #00000000;" @click="action_Close"/>
        </div>
        <div class="dm_pop_wrap video">
            <div class="dm_pop_title_wrap">
                <span class="dm_pop_title">{{ option?.additionalTitle }}</span>
                <img @click="action_Close" src="../../assets/img/demo_intro/dm_close.svg" alt="" class="btn">
            </div>
            <div class="dm_video_wrap">
                <video ref="videoPlayer" class="video-js dm_video" controls preload="true"></video>
                <div class="dm_video_control web" @click="action_PlayPause" >
                    <button class="btn_dm_video">
                        <img v-if="isPlaying" src="../../assets/img/demo_intro/pause.svg" alt="">
                        <img v-if="!isPlaying" src="../../assets/img/demo_intro/play.svg" alt="">
                    </button>
                </div>
            </div>
            
            <div class="dm_video_infomation">
                {{ option?.additionalDesc }}
            </div>
            <button class="btn_dm_video mobile" @click="action_PlayPause" >
                <img v-if="isPlaying" src="../../assets/img/demo_intro/pause.svg" alt="">
                <img v-if="!isPlaying" src="../../assets/img/demo_intro/play.svg" alt="">
            </button>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'

export default {
    props: {
        isShow: Boolean,
        option: Object,
    },
    data() {
        return {
            player: undefined,
            isPlaying: false,
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == true) {
                    this.initVideoPlayer()
                }
            }
        }
    },
    methods: {
        initVideoPlayer() {
            videojs.log.level('all')
            if (this.player != undefined) {
                this.player.src(this.option.sources)
                this.player.play()
            } else {
                console.log(this.option)
                this.player = videojs(this.$refs.videoPlayer, this.option, () => {
                    this.player.ready(() => {
                        this.player.play()
                    })
                    this.player.on('play', () => {
                        this.isPlaying = true
                    })
                    this.player.on('pause', () => {
                        this.isPlaying = false
                    })
                    this.player.on('ended', () => {
                        this.isPlaying = false
                    })
                    this.player.on('error', (err) => {
                        console.log('Error occurred:', err)
                    })
                })
            }
        },
        action_PlayPause() {
            if (this.player.ended()) {
                this.player.src(this.option.sources)
                this.player.play()
            }
            else if (this.player.paused()) {
                this.player.play()
            }
            else {
                this.player.pause()
            }
        },
        action_Close() {
            console.log('here')
            this.$emit('close')
        }
    },
    beforeUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }
}
</script>
