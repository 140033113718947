<template>
    <div class="regist_form_wrap">
        <div class="regist_description">
            <img src="@/assets/img/regist_thirdparty/ic_exclamation_circle.png" alt="">
            <div>
                카카오톡 연동 신청 시 <b class="black_txt">비즈니스 채널 인증이 완료</b>되어 있어야 합니다. 비즈니스 채널 인증 방법은 <a href="https://therapeutic-lavender-21e.notion.site/15070b880bbf80d49553cb76ad6a1b22" target="_blank">매뉴얼</a>을 확인해 주세요.
            </div>
        </div>
        <Step1
            v-if="isStep1"
            :model="model"
            @next="next"
            @back="back"
            @update="update"
        />
        <Step2
            v-if="isStep2"
            :model="model"
            @next="next"
            @back="back"
            @update="update"
        />
        <Done
            v-if="isDone"
            :model="model"
            @next="next"
            @back="back"
            @update="update"
        />
    </div>
</template>

<script>
import Step1 from './RegistKakaoFriendStep1.vue'
import Step2 from './RegistKakaoFriendStep2.vue'
import Done from './RegistKakaoFriendDone.vue'

const PhaseEnum = {
    step1: 'step1',
    step2: 'step2',
    done: 'done'
}
export default {
    components: { Step1, Step2, Done },
    data() {
        return {
            phase: PhaseEnum.step1,
            model: {
                tempHospitalName: undefined,
                realName: undefined,
                phone: undefined,
            }
        }
    },
    computed: {
        isStep1: function() { return this.phase === PhaseEnum.step1 },
        isStep2: function() { return this.phase === PhaseEnum.step2 },
        isDone: function() { return this.phase === PhaseEnum.done }
    },
    methods: {
        // MARK: - Emit
        async next() {
            if (this.phase === PhaseEnum.step1) {
                this.phase = PhaseEnum.step2
            }
            else if (this.phase === PhaseEnum.step2) {
                this.phase = PhaseEnum.done
            }
        },
        back() {
            if (this.phase === PhaseEnum.step1) {
                this.$router.push({name: 'RegistChannels'})
            }
            else if (this.phase === PhaseEnum.step2) {
                this.phase = PhaseEnum.step1
            }
        },
        update(newModel) {
            this.model = {
                ...this.model,
                ...newModel
            }
        }
    }
}
</script>