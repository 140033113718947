<template style="min-height: 100%; background: #ddd">
    <div class="regist_wrap">
        <header class="regist_header">
            <a href="#" @click="action_Move"><img src="../../assets/img/header/logo.png" alt=""></a>
        </header>
        <div class="regist_w1180">
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.checkPath()
    },

    methods: {
        checkPath: function() {
            // console.log(this.$route.fullPath)
            if (this.$route.fullPath === '/regist' || this.$route.fullPath === '/regist/') {
                this.$router.push({name: 'RegistMain'})
            }
        },

        action_Move: function() {
            this.$router.push({name: 'RegistMain'})
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/regist_thirdparty.css';


</style>